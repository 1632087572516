<template>
  <NuxtPwaAssets />
  <div class="min-h-dvh flex flex-col bg-lbg">
    <LayoutOffline />
    <!-- Full Height and Width; not responsive on screens below 320 px-->
    <main class="flex flex-col grow items-center">
      <div class="flex flex-col grow place-items-center p-2 max-w-xl w-full">
        <!-- Logo, about 1/3 of height -->
        <a
          :href="`http://www.${domain}.com`"
          class="flex grow items-center mb-4"
          aria-label="Go back to Home Page"
        >
          <Brand class="text-lmt" />
        </a>
        <!-- Rest, about 2/3 of height -->
        <div class="w-full grow">
          <div class="p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow">
            <slot />
          </div>
        </div>
      </div>
    </main>
    <UNotifications />
  </div>
</template>
<script setup lang="ts">
const { public: { domain } } = useRuntimeConfig()
</script>
