<template>
  <h1
    v-if="domain === 'shairz'"
    :data-nav="nav"
    class="font-['Varela_Round'] font-bold text-6xl md:text-7xl xl:text-8xl data-[nav=true]:text-2xl data-[nav=true]:landscape:text-3xl data-[nav=true]:landscape:xl:text-4xl"
  >
    shairz
  </h1>
  <img
    v-else-if="nav"
    src="~/assets/oloryn/images/logo.svg"
    class="h-8"
    alt="Oloryn Logo"
  >
  <img
    v-else
    src="~/assets/oloryn/images/logo-dark.svg"
    class="h-24"
    alt="Oloryn Logo"
  >
</template>
<script setup lang="ts">
interface Props {
  nav?: boolean
}
withDefaults(defineProps<Props>(), {
  nav: false
})
const { public: { domain } } = useRuntimeConfig()
</script>
